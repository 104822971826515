import styled from "styled-components";
import logo from "../assets/images/logoHeader.svg";
import MarkerIcon from "../assets/images/markerIcon.svg";
import { BodyMedium } from "../commonComponents/Text";
import { sectionContext } from "../context/sectionSelected";
import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Linkedin from "../assets/images/linkedin.svg";

export default function FooterResponsive() {
  const { setSection } = useContext(sectionContext);
  const navigate = useNavigate();
  const location = useLocation();
  function scrollToSection(nextSection: string) {
    if (nextSection === "home") {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 100);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 200);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 300);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 400);
      return;
    } else if (nextSection === "description") {
      const prevElementPosition =
        document.getElementById(nextSection)?.offsetTop;
      if (prevElementPosition) {
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 100);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 200);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 300);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 400);
      }
      return;
    } else if (nextSection === "footer") {
      const prevElementPosition = document.getElementById("press")?.offsetTop;
      if (prevElementPosition) {
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 100);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 200);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 300);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 400);
      }
      return;
    } else if (nextSection === "product") {
      const prevElementPosition = document.getElementById("product")?.offsetTop;
      if (prevElementPosition) {
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 300,
          });
        }, 100);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 300,
          });
        }, 200);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 300,
          });
        }, 300);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 300,
          });
        }, 400);
      }
      return;
    } else if (nextSection === "verticals") {
      const prevElementPosition =
        document.getElementById("verticals")?.offsetTop;
      if (prevElementPosition) {
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition,
          });
        }, 100);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition,
          });
        }, 200);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition,
          });
        }, 300);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition,
          });
        }, 400);
      }
      return;
    }

    const nextElementPosition = document.getElementById(nextSection)?.offsetTop;
    if (nextElementPosition) {
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 100);
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 200);
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 300);
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 400);
    }
  }
  return (
    <Container id="footer">
      <TopContainerResponsive>
        <LogoContainer>
          <img src={logo} alt="logo" />
        </LogoContainer>
        <AddressContainer>
          <img src={MarkerIcon} alt="marker icon" />
          <BodyMedium>
            San Sebastián, Paseo de Miramón 170, 3ª planta, 20014, España.
          </BodyMedium>
        </AddressContainer>
        <LinkedinIcon
          href="https://www.linkedin.com/company/cultzyme/"
          target="_blank"
        >
          <img src={Linkedin} alt="linkedin icon" />
        </LinkedinIcon>
      </TopContainerResponsive>
      <BottomContainer>
        <Link
          onClick={() => {
            if (location.pathname !== "/") {
              navigate("/");
              setTimeout(() => {
                scrollToSection("home");
              }, 100);
            } else {
              scrollToSection("home");
            }
          }}
        >
          About
        </Link>
        <Link
          onClick={() => {
            if (location.pathname !== "/") {
              navigate("/");
              setTimeout(() => {
                setSection("verticals");
              }, 100);
            } else {
              scrollToSection("verticals");
            }
          }}
        >
          Industries
        </Link>
        <Link
          onClick={() => {
            if (location.pathname !== "/") {
              navigate("/");
              setTimeout(() => {
                setSection("team");
              }, 100);
            } else {
              scrollToSection("support");
            }
          }}
        >
          Team
        </Link>
        <Link
          onClick={() => {
            if (location.pathname !== "/") {
              navigate("/");
              setTimeout(() => {
                setSection("support");
              }, 100);
            } else {
              scrollToSection("press");
            }
          }}
        >
          Support & Partners
        </Link>
        <Link
          onClick={() => {
            if (location.pathname !== "/") {
              navigate("/");
              setTimeout(() => {
                setSection("press");
              }, 100);
            } else {
              scrollToSection("contact");
            }
          }}
        >
          News
        </Link>
        <Link
          onClick={() => {
            if (location.pathname !== "/") {
              navigate("/");
              setTimeout(() => {
                setSection("contact");
              }, 100);
            } else {
              scrollToSection("footer");
            }
          }}
        >
          Contact
        </Link>
      </BottomContainer>
      <PrivacyPolicy
        onClick={() => {
          navigate("/privacy-policy");
        }}
      >
        Privacy Policy
      </PrivacyPolicy>
      <CopyRightContainer>Cultzyme 2023</CopyRightContainer>
    </Container>
  );
}

const Container = styled.div`
  background-color: #0f1621;
  padding: 64px 32px 32px;
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 56px;
  * {
    color: white;
  }
`;
const TopContainerResponsive = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 21px;
`;
const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const LogoContainer = styled.div`
  img {
    width: 165px;
    height: auto;
  }
`;
const AddressContainer = styled.div`
  font-size: 1rem;
  color: white;
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  justify-content: start;
  align-items: start;
  img {
    padding-top: 8px;
  }
`;
const CopyRightContainer = styled.div`
  color: #9d9d9d;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const Link = styled.a`
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  &.btn {
    padding: 8px 24px;
    background-color: #2e6fff;
    border-radius: 4px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: flex;
    width: 108px;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
  }
  &.btn:hover {
    color: white;
    background-color: #286dc2;
  }
`;
const LinkedinIcon = styled.a`
  cursor: pointer;
  img {
    width: 28px;
    height: auto;
    border-radius: 4px;
  }
`;
const PrivacyPolicy = styled.a`
  cursor: pointer;
  color: #9d9d9d;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: -auto;
`;