import styled from "styled-components";
import logo from "../assets/images/logoHeader.svg";
import MarkerIcon from "../assets/images/markerIcon.svg";
import {
  BodyMedium,
} from "../commonComponents/Text";
import { sectionContext } from "../context/sectionSelected";
import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Linkedin from "../assets/images/linkedin.svg";

export default function Footer() {
  const { setSection } = useContext(sectionContext);
  const navigate = useNavigate();
  const location = useLocation();
  function scrollToSection(nextSection: string) {
    if (nextSection === "home") {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 100);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 125);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 150);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 175);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 200);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 300);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 350);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 375);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 400);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 425);
      return;
    } else if (nextSection === "verticals") {
      const prevElementPosition =
        document.getElementById(nextSection)?.offsetTop;
      if (prevElementPosition) {
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight,
          });
        }, 100);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight,
          });
        }, 125);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight,
          });
        }, 150);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight,
          });
        }, 175);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight,
          });
        }, 100);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight,
          });
        }, 200);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight,
          });
        }, 300);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight,
          });
        }, 400);
      }
      return;
    } else if (nextSection === "footer") {
      const prevElementPosition = document.getElementById("press")?.offsetTop;
      if (prevElementPosition) {
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 100);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 125);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 150);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 175);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 200);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 300);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + window.innerHeight + window.innerHeight,
          });
        }, 400);
      }
      return;
    } else if (nextSection === "product") {
      const prevElementPosition = document.getElementById("product")?.offsetTop;
      if (prevElementPosition) {
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 500,
          });
        }, 100);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 500,
          });
        }, 125);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 500,
          });
        }, 150);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 500,
          });
        }, 175);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 500,
          });
        }, 200);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 500,
          });
        }, 300);
        setTimeout(() => {
          window.scrollTo({
            top: prevElementPosition + 500,
          });
        }, 400);
      }
      return;
    }

    const nextElementPosition = document.getElementById(nextSection)?.offsetTop;
    if (nextElementPosition) {
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 100);
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 125);
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 150);
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 175);
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 200);
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 300);
      setTimeout(() => {
        window.scrollTo({
          top: nextElementPosition - window.innerHeight,
        });
      }, 400);
    }
  }
  return (
    <Container id="footer">
      <Row>
        <LeftContainer>
          <TopContainer>
            <LogoContainer>
              <img src={logo} alt="logo" />
            </LogoContainer>
            <AddressContainer>
              <img src={MarkerIcon} alt="marker icon" />
              <BodyMedium>
                San Sebastián, Paseo de Miramón 170, 3ª planta, 20014, España.
              </BodyMedium>
            </AddressContainer>
            <LinkedinIcon
              href="https://www.linkedin.com/company/cultzyme/"
              target="_blank"
            >
              <img src={Linkedin} alt="linkedin icon" />
            </LinkedinIcon>
          </TopContainer>
        </LeftContainer>
        <RightContainer>
          <LinksContainer>
            <Link
              onClick={() => {
                if (location.pathname !== "/") {
                  navigate("/");
                  setTimeout(() => {
                    scrollToSection("product");
                  }, 100);
                } else {
                  scrollToSection("product");
                }
              }}
            >
              About
            </Link>
            <Link
              onClick={() => {
                if (location.pathname !== "/") {
                  navigate("/");
                  setTimeout(() => {
                    setSection("verticals");
                  }, 100);
                } else {
                  setSection("verticals");
                }
              }}
            >
              Industries
            </Link>
            <Link
              onClick={() => {
                if (location.pathname !== "/") {
                  navigate("/");
                  setTimeout(() => {
                    setSection("team");
                  }, 100);
                } else {
                  setSection("team");
                }
              }}
            >
              Team
            </Link>
          </LinksContainer>
          <LinksContainer>
            <Link
              onClick={() => {
                if (location.pathname !== "/") {
                  navigate("/");
                  setTimeout(() => {
                    setSection("support");
                  }, 100);
                } else {
                  setSection("support");
                }
              }}
            >
              Support & Partners
            </Link>
            <Link
              onClick={() => {
                if (location.pathname !== "/") {
                  navigate("/");
                  setTimeout(() => {
                    setSection("press");
                  }, 100);
                } else {
                  setSection("press");
                }
              }}
            >
              News
            </Link>
            <Link
              onClick={() => {
                if (location.pathname !== "/") {
                  navigate("/");
                  setTimeout(() => {
                    setSection("contact");
                  }, 100);
                } else {
                  setSection("contact");
                }
              }}
            >
              Contact
            </Link>
          </LinksContainer>
        </RightContainer>
      </Row>
      <BottomContainer>
        <PrivacyPolicy onClick={()=>{
          navigate("/privacy-policy");
        }}>Privacy Policy</PrivacyPolicy>
        <CopyRightContainer>Cultzyme 2023</CopyRightContainer>
      </BottomContainer>
    </Container>
  );
}
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
const Container = styled.div`
  background-color: #0f1621;
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 0 108px 16px;
`;
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 62px 32px 0;
  width: 260px;
  gap: 16px;
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  justify-content: flex-end;
`;
const LogoContainer = styled.div`
  img {
    width: 140px;
    height: auto;
    @media (min-width: 1500px) {
      width: 200px;
    }
    @media (min-width: 1900px) {
      width: 240px;
    }
  }
`;
const AddressContainer = styled.div`
  font-size: 1rem;
  color: white;
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 600px;
  justify-content: start;
  align-items: center;
`;
const CopyRightContainer = styled.div`
  color: #9d9d9d;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: end;
  padding-right: 32px;
  @media (min-width: 1500px) {
    font-size: 14px;
  }
  @media (min-width: 1900px) {
    font-size: 16px;
  }
`;
const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 42px;
  width: 100%;
  padding: 62px 32px 0px;
`;
const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  a {
    font-size: 1.1rem;
    font-weight: 500;
    color: white;
    text-decoration: none;
    @media (min-width: 1500px) {
      font-size: 20px;
    }
    @media (min-width: 1900px) {
      font-size: 24px;
    }
    &:hover {
      color: #286dc2;
    }
    &.btn {
      padding: 8px 24px;
      background-color: #2e6fff;
      border-radius: 4px;
      transition: all 0.3s ease-in-out;
    }
    &.btn:hover {
      color: white;
      background-color: #286dc2;
    }
  }
`;
const Link = styled.a`
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  &.btn {
    padding: 8px 24px;
    background-color: #2e6fff;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
  }
  &.btn:hover {
    color: white;
    background-color: #286dc2;
  }
`;
const LinkedinIcon = styled.a`
  cursor: pointer;
  img {
    width: 28px;
    height: auto;
    border-radius: 4px;
  }
`;
const PrivacyPolicy = styled.a`
  cursor: pointer;
  color: #9d9d9d;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: end;
  padding-right: 32px;
  @media (min-width: 1500px) {
    font-size: 14px;
  }
  @media (min-width: 1900px) {
    font-size: 16px;
  }
`;
